/* Container principal que segura o conteúdo */
.home-container {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    overflow: hidden;
    text-align: center;
  }
  
  /* Vídeo de fundo */
  .background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1; /* Coloca o vídeo atrás do conteúdo */
  }
  
  /* Camada escura sobre o vídeo */
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.695); /* Ajusta a opacidade para controlar a escuridão */
    z-index: 1;
  }
  
  /* Estilos para o conteúdo */
  .content {
    z-index: 2;
  }
  
  .content h1 {
    font-size: 3rem;
    color: #CC9C5B; /* Cor do título semelhante à imagem */
    margin-bottom: 20px;
  }
  
  .content h2 {
    font-size: 2rem;
    color: #ffffff;
    margin-bottom: 15px;
  }
  
  .content p {
    font-size: 1.2rem;
    color: #CC9C5B;
  }
  