/* Certifique-se de que a body e html estão sem overflow */
html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Remove rolagem horizontal para todo o site */
}

.section_contato {
    min-height: 80vh;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 50px 0;
    width: 100%; /* Garante que a seção ocupe 100% da largura */
    box-sizing: border-box;
}

.h1_contato {
    font-family: 'Oxygen', sans-serif;
    color: #CC9C5B;
    padding-bottom: 50px;
    text-align: center;
    font-size: 2.4rem;
}

.div_redes_sociais {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-bottom: 40px;
}

.rede_contato {
    color: #CC9C5B;
    font-size: 2.4rem;
    transition: color 0.3s;
}

.rede_contato:hover {
    color: aliceblue;
}

.endereco,
.contato_info {
    font-family: 'Oxygen', sans-serif;
    color: white;
    text-align: left;
    margin-bottom: 40px;
}

.endereco h2,
.contato_info h2 {
    color: #CC9C5B;
    font-size: 1.4rem;
    margin-bottom: 10px;
}

.endereco p,
.contato_info p {
    margin: 0;
    font-size: 1.2rem;
    color: white;
}

.google_map {
    margin-top: 40px;
    width: 100%; /* Ocupará 100% da largura disponível */
    max-width: 500px; /* Limitará a largura do mapa */
    height: 350px;
    border: none;
}

.container_contato {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    gap: 50px;
    box-sizing: border-box; /* Evita que o conteúdo ultrapasse os limites */
}

.left_section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 30px;
    max-width: 500px;
}

.right_section {
    flex-grow: 1;
    width: 100%;
    text-align: center;
}

.hr_contato {
    border: 1px solid #CC9C5B;
    width: 80%;
    margin: 30px auto;
}

.copyright {
    font-family: 'Oxygen', sans-serif;
    color: #CC9C5B;
    text-align: center;
    margin-top: 20px;
    font-size: 1rem;
   text-decoration: none;
}

.link_insta {
    font-family: 'Oxygen', sans-serif;
    color: #CC9C5B;
    text-align: center;
    margin-top: 20px;
    font-size: 1rem;
   text-decoration: none;
}

.link_insta:hover {
    color: white;
}

.scroll_to_top {
    background-color: #801111;
    border: none;
    color: white;
    padding: 10px 15px;
    font-size: 24px;
    border-radius: 50%;
    position: absolute;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .scroll_to_top:hover {
    background-color: #555;
  }

/* Ajustes para telas menores */
@media (max-width: 768px) {
    .container_contato {
        flex-direction: column;
        padding: 20px;
    }

    .right_section {
        margin-top: 20px;
        width: 100%;
    }

    .google_map {
        height: 300px;
        width: 100%; /* Garante que o mapa ocupe 100% da largura no mobile */
        max-width: 100%; /* Remove restrição de largura no mobile */
        margin: 0 auto; /* Centraliza o mapa */
    }

    .h1_contato {
        font-size: 2rem; /* Reduz o tamanho da fonte do título no mobile */
        padding: 20px 0;
        display: block; /* Garante que o título esteja visível */
    }
}
