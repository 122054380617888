/* Estilos gerais para o header */
.header {
    background-color: black;
    height: 15vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    position: relative;
}

.container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.logo {
    width: 150px; /* Ajuste do tamanho da logo */
    height: auto;
    transform: scale(0.3);
    object-fit: contain;
    transition: transform 0.3s ease;
}

.logo:hover {
    transform: scale(0.2);
}

/* Menu de navegação padrão (desaparecerá em telas pequenas) */
.div_navbar {
    flex-grow: 1;
    display: flex;
    justify-content: center;
}

.navbar-nav {
    list-style: none;
    display: flex;
    gap: 30px;
}

.nav-item {
    display: inline-block;
}

.nav-link {
    color: #CC9C5B;
    text-decoration: none;
    font-size: 1.2rem;
    padding: 5px 10px;
}

.nav-link:hover {
    color: #ffffff;
}

/* Ícone do menu hamburguer (escondido em telas grandes) */
.hamburger {
    display: none;
    cursor: pointer;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 20px;
}

.hamburger div {
    width: 100%;
    height: 4px;
    background-color: #CC9C5B;
    transition: all 0.3s ease;
}

/* Menu que cobre a tela inteira */
.mobile-menu {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1000;
    transition: all 0.3s ease;
}

.mobile-menu.open {
    display: flex;
}

/* Links do menu mobile */
.mobile-menu ul {
    list-style: none;
    text-align: center;
}

.mobile-menu ul li {
    margin: 20px 0;
}

.mobile-menu ul li a {
    color: #CC9C5B;
    font-size: 2rem;
    text-decoration: none;
}

.mobile-menu ul li a:hover {
    color: white;
}

/* Botão de fechar (X) no menu mobile */
.close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 2rem;
    color: #CC9C5B;
    cursor: pointer;
}

.contato {
    text-decoration: none;
    border:solid 1px #CC9C5B;
    background-color: #f4433600;
    color: #ffffff;
    padding: 14px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 10px 10px 10px 10px;
}

.contato:hover {
    color: #000000;
    background-color: #CC9C5B;
}

/* Media queries para telas menores */
@media (max-width: 768px) {
    .div_navbar {
        display: none;
    }

    .hamburger {
        display: flex;
    }
    .logo:hover {
        display: none;
    }
}
