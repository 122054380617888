/* Container principal da seção */
.section_sobre {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    padding: 50px;
    background-color: #ffffff;
    height: 80vh;
}

.sobre-content {
    display: flex;
    flex-wrap: wrap; /* Para tornar a seção responsiva */
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    width: 100%;
}

/* Estilização da imagem */
.sobre-image img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin-right: 30px;
}

/* Estilização do texto */
.sobre-text {
    flex: 1;
    color: #333;
    padding-left: 40px; /* Adiciona espaçamento para jogar os textos mais à direita */
}

.title {
    font-family: 'Oxygen', sans-serif;
    font-weight: 400;
    font-style: italic;
    font-size: 2.5rem;
    color: #2e7d32; /* Verde */
    margin-bottom: 30px; /* Aumenta o espaçamento abaixo do título */
}

/* Títulos dos compromissos */
.commitment-title {
    font-family: 'Oxygen', sans-serif;
    font-weight: 400;
    font-style: italic;
    color: #CC9C5B;
    font-size: 1.8rem;
    margin-bottom: 15px; /* Aumenta o espaçamento abaixo dos subtítulos */
}

/* Texto de descrição */
p {
    font-family: 'Oxygen', sans-serif;
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 30px; /* Aumenta o espaçamento entre parágrafos */
}

/* Assinatura e OAB */
.signature {
    font-family: 'Great Vibes', cursive;
    font-size: 2rem;
    color: #333;
    text-align: right; /* Alinha o nome à direita */
    margin-bottom: 5px; /* Pequeno espaçamento entre assinatura e OAB */
}

.oab {
    font-family: 'Oxygen', sans-serif;
    font-size: 1rem;
    color: #333;
    text-align: right; /* Alinha o número da OAB à direita */
}

/* Nova seção com contadores */
.counters-section {
    position: relative; /* Importante para o posicionamento correto do ::before */
    background-image: url('../../public/bnu.jpeg'); /* Caminho correto da imagem de fundo */
    background-size: cover; /* Tenta cobrir a área sem distorcer a imagem */
    background-position: center; /* Centraliza a imagem */
    background-attachment: fixed; /* Mantém a imagem fixa durante o scroll */
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    color: white;
    text-align: center;
    z-index: 1; /* Coloca o conteúdo acima do ::before */
}

.counters-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Escurece a imagem com um overlay */
    z-index: -1; /* Coloca o before abaixo do conteúdo, mas acima da imagem */
}

.counter-container {
    display: flex;
    justify-content: space-around;
    width: 100%;
    max-width: 1200px;
}

.counter-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Oxygen', sans-serif;
    color: #CC9C5B;
}

.counter-item i {
    font-size: 2rem;
    margin-bottom: 10px;
}

.counter-item p {
    
    margin: 0;
    font-size: 1.2rem;
}

.counters-section p:first-child {
    font-size: 1.8rem;
    font-weight: bold;
}

/* Responsividade */
@media (max-width: 768px) {
    .section_sobre {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: auto; /* Permitir que a altura se ajuste ao conteúdo */
        padding: 20px;
        background-color: #ffffff;
    }

    .sobre-content {
        flex-direction: column;
        text-align: center;
        align-items: center; /* Certifica-se de que a imagem e o texto estejam centralizados */
    }

    .sobre-image img {
        width: 100%; /* A imagem ocupará 100% da largura disponível */
        max-width: 300px; /* Limita o tamanho máximo da imagem */
        height: auto; /* Mantém a proporção da imagem */
        margin-bottom: 20px;
    }

    .sobre-text {
        padding: 0 20px;
    }

    .title {
        font-size: 2rem;
    }

    .commitment-title {
        font-size: 1.5rem;
    }

    p {
        font-size: 1rem;
    }

    .counters-section {
        height: 70vh; /* Aumentar a altura para telas menores */
        flex-direction: column; /* Alinha os itens verticalmente */
    }

    .counter-container {
        flex-direction: column;
    }

    .counter-item {
        margin-bottom: 20px;
    }
}