.section_depoimentos {
  position: relative;
  background-image: url('../../public/justice2.webp'); 
  background-size: cover;
  background-position: center;
  padding: 100px 20px; 
  text-align: center;
  color: #fff;
  min-height: 50vh;
}

.depoimentos_h2 {
  font-family: 'Oxygen', sans-serif;
  color: #CC9C5B;
}

.section_depoimentos::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.796); /* Escurece a imagem de fundo */
  z-index: 1; /* Coloca o background antes do conteúdo */
}

.section_depoimentos h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  position: relative;
  z-index: 2; /* Garante que o texto fique acima do background escuro */
}

.swiper-container {
  width: 100%;
  max-width: 1200px; /* Aumenta a largura máxima do Swiper */
  height: 500px; /* Define a altura do Swiper */
  margin: 50px auto 0; /* Move o conteúdo mais para baixo */
  position: relative;
  z-index: 2; /* Garante que o swiper também fique acima do background */
}

.testimonial {
  padding: 100px;
  border-radius: 10px;
}

.testimonial p {
  font-size: 1.4rem;
  margin-bottom: 10px;
  font-family: 'Oxygen', sans-serif;
}

.testimonial h4 {
  font-size: 1.4rem;
  font-weight: bold;
  font-family: 'Oxygen', sans-serif;
  color: #CC9C5B;
}

/* Estilos das setas de navegação */
.swiper-button-next, .swiper-button-prev {
  color: #fff;
  position: absolute;
  top: 50%;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  z-index: 3; /* Coloca as setas acima do conteúdo */
}

.swiper-button-next {
  right: -7px; /* Move a seta para a extremidade direita */
}

.swiper-button-prev {
  left: -7px; /* Move a seta para a extremidade esquerda */
}

/* Responsividade */
@media (max-width: 768px) {
  .section_depoimentos h2 {
    font-size: 2rem;
  }

  .testimonial p {
    font-size: 1rem;
  }

  .testimonial h4 {
    font-size: 0.9rem;
  }
}