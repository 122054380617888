.atuacao_section {
    padding: 60px 0;
    background-color: #f9f9f9;
    text-align: center; /* Centraliza os elementos */
  }
  
  .atuacao_titulo {
    font-size: 2.5rem;
    color: #2f6217; /* Verde mais forte */
    margin-bottom: 20px;
    font-family: 'Oxygen', sans-serif;
    font-weight: bold;
  }
  
  .atuacao_balanca {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
  }
  
  .atuacao_linha {
    width: 50px;
    border: none;
    height: 2px;
    background-color: #b28b33; /* Dourado */
  }
  
  .atuacao_balanca_icone::before {
    content: '\2696'; /* Código Unicode da balança */
    font-size: 2rem;
    color: #b28b33; /* Dourado */
    margin: 0 15px;
  }
  
  .atuacao_row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; /* Organiza uniformemente os cards */
    gap: 20px;
    max-width: 1200px; /* Limita a largura máxima da área dos cards */
    margin: 0 auto; /* Centraliza os cards na página */
  }
  
  .atuacao_card {
    background-color: #e6e6e6;
    font-family: 'Oxygen', sans-serif;
    font-weight: bold;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: calc(33.333% - 20px); /* Para garantir que três cards caibam em uma linha */
    animation: fadeInLeft 1s ease-in-out;
  }
  
  .atuacao_card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  }
  
  .atuacao_icon {
    font-size: 3rem;
    color: #b28b33; /* Dourado */
    margin-bottom: 15px;
  }
  
  .atuacao_card_title {
    font-size: 1.3rem;
    color: #b28b33; /* Dourado */
    margin-bottom: 10px;
  }
  
  .atuacao_card_text {
    font-size: 0.9rem;
    color: #2f6217; /* Verde mais forte */
  }
  
  @keyframes fadeInLeft {
    0% {
      opacity: 0;
      transform: translateX(-50%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  /* Responsividade */
  @media (max-width: 992px) {
    .atuacao_card {
      width: calc(50% - 20px); /* Ajusta para 2 cards por linha em telas menores */
    }
  }
  
  @media (max-width: 768px) {
    .atuacao_card {
      width: 100%; /* Um card por linha em telas pequenas */
      margin-bottom: 20px;
    }
  }
  
  @media (max-width: 576px) {
    .atuacao_card {
      padding: 15px;
    }
  
    .atuacao_titulo {
      font-size: 2rem;
    }
  
    .atuacao_icon {
      font-size: 2.5rem;
    }
  }