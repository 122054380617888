.card-section {
    position: relative;
    background-color: #333333; /* Fundo escuro */
    color: white;
    padding: 30px;
    text-align: center;
    border-radius: 20px; /* Arredondamento */
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2); /* Sombra */
    max-width: 800px;
    margin: -100px auto 50px; /* Negativo para sobrepor entre as seções */
    z-index: 10;
  }
  
  .card-section h2 {
    font-size: 2.5rem;
    color: #CC9C5B; /* Cor do título */
    margin-bottom: 10px;
  }
  
  .card-section p {
    font-size: 1.2rem;
    color: #ffffff;
    margin-bottom: 20px;
  }
  
  .card-section .button {
    font-size: 1.2rem;
    color: #ffffff;
    background-color: transparent;
    border: 2px solid #CC9C5B;
    padding: 10px 20px;
    border-radius: 10px;
    text-decoration: none;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .card-section .button:hover {
    background-color: #CC9C5B;
    color: #000000;
  }
  